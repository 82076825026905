/**
 * See https://css-tricks.com/lazy-loading-images-with-vue-js-directives-and-intersection-observer/
 *
 * I used AmazonQ to help set up working directive
 */

export default {
    mounted(el) {
        // console.log('LazyLoad directive mounted'); // Debug log

        function loadImage() {
            const imageElement = el.tagName === 'IMG' ? el : el.querySelector('img');

            if (imageElement) {
                const imageUrl = imageElement.getAttribute('data-url');
                if (imageUrl) {
                    // console.log('Loading image:', imageUrl); // Debug log
                    imageElement.src = imageUrl;
                }
            }
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    loadImage();
                    observer.unobserve(el);
                }
            });
        });

        observer.observe(el);
    }
    // inserted: el => {
    //
    //     console.log('inserted', el)
    //
    //     function loadImage() {
    //         console.log('loadImage')
    //         const imageElement = Array.from(el.children).find(
    //             el => el.nodeName === "IMG"
    //         );
    //         if (imageElement) {
    //             imageElement.addEventListener("load", () => {
    //                 setTimeout(() => el.classList.add("loaded"), 100);
    //             });
    //             imageElement.addEventListener("error", () => console.log("error"));
    //             imageElement.src = imageElement.dataset.url;
    //         }
    //     }
    //
    //     function handleIntersect(entries, observer) {
    //         entries.forEach(entry => {
    //             if (entry.isIntersecting) {
    //                 loadImage();
    //                 observer.unobserve(el);
    //             }
    //         });
    //     }
    //
    //     function createObserver() {
    //         const options = {
    //             root: null,
    //             threshold: "0"
    //         };
    //         const observer = new IntersectionObserver(handleIntersect, options);
    //         observer.observe(el);
    //     }
    //
    //     if (window["IntersectionObserver"]) {
    //         createObserver();
    //     } else {
    //         loadImage();
    //     }
    // }
}
async function loadJsonFile(file) {
    const response = await fetch(file)
    if (response.status === 200) {
        const json = await response.json()
        return json;
    } else {
        console.error('File '+file+' not found');
        return null;
    }
}

const defaultSlideshow = [
    {url: "/images/slideshow/_Y3A7576_5_4.JPG", "title": "October Sunset", captionClass: "text-light", navClass: ''},
    {url: "/images/slideshow/_76A0052.JPG", "title": "Frenzy", captionClass: "text-light"},
    {url: "/images/slideshow/_76A3390.JPG", "title": "Solitude", captionClass: "text-dark"},
    {url: "/images/slideshow/_76A5755_4_3.JPG", "title": "Was it something they said?", captionClass: "text-dark"},
    {url: "/images/slideshow/_76A8171_70_69.JPG", "title": "Thin Red Line", captionClass: "text-light"},
    {url: "/images/slideshow/_76A8279_8_7.JPG", "title": "Homecoming", captionClass: "text-light"},
    {url: "/images/slideshow/_Y3A3747_6_5.JPG", "title": "A Pillar Point morning", captionClass: "text-light"},
];

export default {
    namespaced: true,
    state: {
        gallery: [],
        slideshow: []
    },
    getters: {
        gallery: (state) => state.gallery,
        slideshow: (state) => state.slideshow
    },
    mutations: {
        gallery(state, items) {
            state.gallery = items
        },
        slideshow(state, items) {
            state.slideshow = items
        }
    },
    actions: {
        async loadSlideshow(context) {
            try {
                const json = await loadJsonFile('/images/slideshow/slideshow.json');
                if (json) {
                    context.commit('slideshow', json);
                } else {
                    context.commit('slideshow', defaultSlideshow);
                }
            } catch (error) {
                console.error('Error loading slideshow:', error)
                // throw error
            }
        },
    }
}
import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import VueGtag from 'vue-gtag';
import LazyLoadDirective from "@/directives/LazyLoadDirective";
import "bootstrap"

createApp(App)
    .directive("lazyload", LazyLoadDirective)
    .use(store)
    .use(router)
    .use(VueGtag, {
        config: { id: "G-25K8RL069G" }
    }, router)
    .mount('#app')

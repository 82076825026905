<template>
  <nav class="navbar navbar-expand-md py-3 fixed-top">
    <div class="container">
      <router-link to="/" class="navbar-brand d-flex align-items-center">
        <span>
          MossBeachLife.com
        </span>
      </router-link>

      <button @click="visible = !visible"
              class="navbar-toggler"
              :class="visible ? '' : 'collapsed'"
              :aria-expanded="visible ? 'true' : 'false'">
        <span class="visually-hidden"></span>
        <span class="navbar-toggler-icon"></span>
      </button>

      <div id="navbarNav"
           class="collapse navbar-collapse flex-grow-0"
           :class="visible ? 'show' : ''">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link :to="{ name: 'home' }"
                         class="nav-link"
                         :class="{active: $route.name === 'home'}"
                         @click="visible = false">
              Home
            </router-link>
          </li>
          <li class="nav-item" >
            <router-link :to="{ name: 'store' }"
                         class="nav-link"
                         :class="{active: $route.name === 'store'}"
                         @click="visible = false">
              Store
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'prints-categories' }"
                         class="nav-link"
                         :class="{active: $route.name && $route.path.includes('/prints') }"
                         @click="visible = false">
              Prints
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'gallery' }"
                         class="nav-link"
                         :class="{active: $route.name === 'gallery'}"
                         @click="visible = false">
              Gallery
            </router-link>
          </li>
<!--          <li class="nav-item">
            <a href="https://www.j2clark.com/MBL" target="_blank"
               class="nav-link external-link"
               @click="visible = false">
              j2clark.com
              <i class="bi bi-box-arrow-up-right ms-1"></i>
            </a>
          </li>-->

          <!-- Only display when cart icon is NOT shown: d-md-none d-block -->
          <li class="nav-item d-md-none d-block" >
            <router-link :to="{ name: 'cart' }"
                         class="nav-link"
                         :class="{active: $route.name === 'cart'}"
                         @click="visible = false">
              Cart
            </router-link>
          </li>
        </ul>

        <!-- Only display when cart menu is NOT shown: d-none d-md-block-->
        <div class="d-none d-md-block ms-4">
          <router-link :to="{ name: 'cart' }"
                       class="nav-link"
                       :class="{active: $route.name === 'cart'}">
            <div v-if="cartItems">
              <cart-heart-icon :size="25" fill-color="red"/>
            </div>
            <div v-else>
              <cart-outline-icon :size="25"/>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import CartOutlineIcon from 'vue-material-design-icons/CartOutline.vue';
import CartHeartIcon from 'vue-material-design-icons/CartHeart.vue';

// https://github.com/robcresswell/vue-material-design-icons
// https://pictogrammers.com/library/mdi/
export default {
  components: {
    CartOutlineIcon,
    CartHeartIcon
  },
  computed: {
    cartItems() {
      const cart = this.$store.getters["cart/cart"];
      return cart && cart.length > 0;
    }
  },
  data() {
    return {
      enableContact: false,
      enableAbout: false,
      visible: false
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    handleClickOutside(event) {
      const navbar = document.getElementById('navbarNav')
      const togglerButton = document.querySelector('.navbar-toggler')

      if (this.visible &&
          !navbar.contains(event.target) &&
          !togglerButton.contains(event.target)) {
        this.visible = false
      }
    }
  },
  watch: {
    '$route.query': {
      handler(newQuery) {
        // Handle the new query parameters here
        if (newQuery.mode === 'enhanced') {
          this.enableContact = true;
          this.enableAbout = true;
          this.$store.commit("prints/setEnablePrints", true);
        }
      },
      immediate: true
    }
  }
}
</script>
<style>
.navbar {
  background-color: var(--bs-body-bg);
  display: inline-block;
}

.navbar-brand {
  transition: all 0.2s ease-in-out;
}

.navbar-brand:hover {
  transform: scale(1.05);
  text-decoration: underline !important;
}

.nav-link {
  position: relative;
  transition: all 0.2s ease-in-out;
}

.nav-link:hover {
  transform: scale(1.10);
  text-decoration: underline !important;
}

.nav-link.active {
  color: var(--bs-link-selected) !important; /* Golden yellow */
  font-weight: 500 !important;
  text-decoration: underline !important;
}

.nav-link.active:hover {
  transform: scale(1.00);
  color: var(--bs-link-selected) !important; /* Maintains the yellow active state */
}

.external-link .nav-link:hover {
  color: var(--bs-external-color) !important;
}

.nav-link:hover::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: var(--bs-body-bg);
  transition: width 0.2s ease;
}
</style>
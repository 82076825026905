import * as Promises from "axios";

export default {
    namespaced: true,
    state: {
        images: null,
        tags: []
    },
    getters: {
        images: (state) => state.images,
        tags: (state) => state.tags
    },
    mutations: {
        setImages(state, images) {
            state.images = images
        },
        setTags(state, tags) {
            state.tags = tags
        }
    },
    actions: {
        async loadImages(context) {

            const data = context.getters.images
            if (data != null) {
                return data;
            }

            try {
                const catFile = `/images/gallery.json`
                const response = await fetch(catFile);
                const data = await response.json();

                // todo: move this into json file
                const mapped = await Promises.all(data.images.map(async image => {
                    const urlSmall = `/images/prints/${image.category}/sm/${image.thumbnail}`;
                    const urlLarge = `/images/prints/${image.category}/lg/${image.thumbnail}`;
                    return {
                        ...image,
                        // title: image.title,
                        large: urlLarge,
                        small: urlSmall,
                    };
                }));

                context.commit('setImages', mapped);
                context.commit('setTags', data.tags.filter(tag => {
                    return tag.toLowerCase() !== 'golden-horizons'
                        && tag.toLowerCase() !== 'scenic-wonders'
                        && tag.toLowerCase() !== 'wild-encounters'
                }));
                return mapped;
            } catch (error) {
                console.error('Error loading category:', error);
                const nullData = []
                context.commit('setImages', nullData);
                return nullData;
            }
        },
        async createImageRows(_, request) {
            const {images, rowHeight, maxWidth, gap} = request;

            if (!images || images.length === 0) return [];

            const rows = [];
            let i = 0;

            while (i < images.length) {
                let rowImages = [];
                let totalAspectRatio = 0;
                let numImages = 0;

                // First pass: calculate total aspect ratio for the row
                while (i + numImages < images.length) {
                    const image = images[i + numImages];
                    const aspectRatio = image.width / image.height;

                    // Calculate what width this image would take at rowHeight
                    const scaledWidth = rowHeight * aspectRatio;
                    const potentialWidth = (totalAspectRatio * rowHeight) + scaledWidth +
                        (numImages > 0 ? gap * numImages : 0);

                    if (potentialWidth > maxWidth && numImages > 0) {
                        break;
                    }

                    totalAspectRatio += aspectRatio;
                    rowImages.push({
                        ...image,
                        aspectRatio
                    });
                    numImages++;
                }

                // Calculate the height that makes the row exactly fill maxWidth
                const totalGapWidth = (numImages - 1) * gap;
                const rowHeightScaled = (maxWidth - totalGapWidth) / totalAspectRatio;

                // Apply the scaled height to all images in the row
                const scaledRowImages = rowImages.map(image => ({
                    ...image,
                    displayWidth: `${Math.floor(rowHeightScaled * image.aspectRatio)}px`,
                    displayHeight: `${Math.floor(rowHeightScaled)}px`
                }));

                rows.push(scaledRowImages);
                i += numImages;
            }

            return rows;
        }
    }
}